import "./App.css";
import { useRef } from "react";
import useMouse from "@react-hook/mouse-position";
import { motion, useScroll } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

function App() {
  const { scrollYProgress } = useScroll();
  const ref = useRef(null);
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  let mouseXPosition = 0;
  let mouseYPosition = 0;

  if (mouse.x !== null) {
    mouseXPosition = mouse.clientX;
  }

  if (mouse.y !== null) {
    mouseYPosition = mouse.clientY;
  }

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 28,
  };

  const defaultAnim = {
    opacity: 1,
    x: mouseXPosition,
    y: mouseYPosition,
    transition: {
      type: "spring",
      mass: 0.6,
    },
  };

  return (
    <div className="App" ref={ref}>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <motion.div
        className="cursor"
        animate={defaultAnim}
        transition={spring}
      />
      <div className="landing">
        <div className="name-container">
          <motion.p
            className="name"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            Sarah Goodroe
          </motion.p>
          <div className="button-container">
            <a href="#social-container">
              <motion.button
                className="contact-button"
                initial={{ opacity: 0 }}
                whileInView={{
                  opacity: 1,
                  transition: { delay: 4, duration: 0.5 },
                }}
                whileHover={{
                  boxShadow: "4px 4px 0px black",
                  transition: { delay: 0 },
                }}
                whileTap={{
                  boxShadow: "2px 2px 0px rgba(0,0,0,0.8)",
                  transition: { delay: 0 },
                }}
              >
                Contact
              </motion.button>
            </a>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { delay: 4.5, duration: 0.5 },
              }}
            >
              <FontAwesomeIcon
                className="emphasis-icon"
                icon={faCircleExclamation}
                color="black"
                size="lg"
              />
            </motion.div>
          </div>
        </div>
        <div className="title-container">
          <motion.p
            className="title"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 1.5, duration: 0.5 }}
          >
            Neuroscientist
          </motion.p>
          <motion.p
            className="title"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 2.5, duration: 0.5 }}
          >
            Software developer
          </motion.p>
          <motion.p
            className="title"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 3.5, duration: 0.5 }}
          >
            Unity engineer
          </motion.p>
        </div>
      </div>
      <div className="about">
        <div className="bio-container">
          <motion.p
            className="bio"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Sarah completed her PhD in cognitive neuroscience from University
            College London in 2021. Now a postdoctoral researcher at the
            University of Pennsylvania, Sarah builds Unity games for research.
            <br></br>
            <br></br>
            Do you need a custom software solution for your website? Are you
            building a game in Unity?{" "}
            <a href="mailto:info@sarahgoodroe.com">Reach out</a>!<br></br>
            <br></br>
            Sarah is based in Philadelphia, PA.
          </motion.p>
          <motion.div
            className="social-container"
            id="social-container"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <a
              href="https://github.com/goodroesarah"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="icon"
                icon={faGithub}
                color="black"
                size="4x"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/sarah-goodroe-347a02237/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="icon"
                icon={faLinkedin}
                color="black"
                size="4x"
              />
            </a>
            <a
              href="https://twitter.com/GoodroeSarah"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="icon"
                icon={faTwitter}
                color="black"
                size="4x"
              />
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default App;
